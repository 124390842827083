import { CircularProgress } from "@mui/material";
import * as Sentry from "@sentry/react";
import { Navigate, useLocation } from "react-router-dom";

import { useSession } from "@/hooks/useSession";
import { useEffect } from "react";
import { identify, page } from "@ignite-analytics/track";

const RequireAuth = ({ children }: { children: JSX.Element }) => {
    const session = useSession();
    const location = useLocation();
    useEffect(() => {
        if (session.data) {
            identify({
                type: "authenticted",
                id: session.data.userId!,
                email: session.data.traits!.email,
                tenantName: session.data.traits?.tenant || "",
            });
            page("auth", location.pathname);
            Sentry.setUser({
                id: session.data.userId,
                tenant: session.data.traits?.tenant,
            });
            Sentry.setTag("app", "auth-sidecar");
        }
    }, [session.data, location.pathname]);

    if (session.loading) return <CircularProgress />;

    if (!session.data) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        const searchparams = new URLSearchParams({ redirect_url: location.pathname });
        return <Navigate to={`/login?${searchparams.toString()}`} state={{ from: location }} replace />;
    }

    return children;
};

export default RequireAuth;
