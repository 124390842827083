import { getCurrentDomain } from "@/utils/getCurrentHostname";
import { LoginFlow } from "@ory/kratos-client";
import * as Sentry from "@sentry/react";
import React from "react";
import { useSearchParams } from "react-router-dom";

async function getError(flow: string): Promise<number> {
    try {
        const kratosPublicUrl = getCurrentDomain() === "ignite" ? import.meta.env.VITE_IGNITE_KRATOS_PUBLIC_URL : import.meta.env.VITE_IGNITE_PROCUREMENT_KRATOS_PUBLIC_URL;
        const res = await fetch(`${kratosPublicUrl}/self-service/login/flows?id=${flow}`, {
            credentials: "include",
        });
        const data: LoginFlow = await res.json();
        const { messages, nodes } = data.ui;

        let errorCode: null | number = null;
        if (messages && messages.length === 1) {
            errorCode = messages[0].id;
        }

        // include some debug info in the sentry report
        const nodesDebugText = JSON.stringify(
            nodes.filter((n) => n.group !== "default").map((n) => ({ group: n.group, messages: n.messages }))
        );
        const messagesJson = (data.ui.messages || []).map((m) => JSON.stringify(m));
        Sentry.captureMessage(`Login failed: flow error: ${flow}`, {
            user: {
                email: localStorage.getItem("lastEmail") ?? undefined,
            },
            extra: { messages: messagesJson, nodes: nodesDebugText, full: JSON.stringify(data) },
        });
        if (errorCode !== null) {
            return errorCode;
        }
        throw new Error("no error code found");
    } catch (e) {
        console.error(e);
        throw new Error("failed to get flow error");
    }
}

interface Props {
    dest: string;
}

const FlowError: React.FC<Props> = ({ dest }) => {
    // ory redirects back to /ui/login?flow=abcd
    // so this page will capture the flow id and redirect back to login with an appropriate message in the query parameter
    // See [1] and [2] for further details
    // [1] https://www.ory.sh/docs/kratos/self-service/flows/user-facing-errors#user-facing-errors-in-the-browser
    // [2] https://www.ory.sh/docs/reference/api#tag/frontend/operation/getLoginFlow
    const [p] = useSearchParams();
    const flow = p.get("flow");
    const baseUrl = getCurrentDomain() === "ignite" ? import.meta.env.VITE_PUBLIC_IGNITE_URL : import.meta.env.VITE_PUBLIC_URL;
    const path = `${baseUrl}${dest}`;

    const allSearchParams: Record<string, string> = {};
    for (const [key, value] of p) {
        allSearchParams[key] = value;
    }

    Sentry.captureMessage(`flow error: flow error: ${flow}`, {
        user: {
            email: localStorage.getItem("lastEmail") ?? undefined,
        },
        extra: allSearchParams,
    });

    if (flow === null) {
        window.location.href = `${path}?error=genericError`;
    } else {
        getError(flow)
            .then((id) => {
                if (id === 4000027) {
                    // special case: user exists, but is not linked. We redirect to a hyggelig page and start recovery flow, also notifying them about it
                    window.location.href = `${baseUrl}/link-required`;
                    return;
                }

                window.location.href = `${path}?error=${id}`;
            })
            .catch(() => {
                Sentry.captureMessage("Failed to get error message for flow", {
                    user: {
                        email: localStorage.getItem("lastEmail") ?? undefined,
                    },
                    extra: allSearchParams,
                });
                window.location.href = `${path}?error=genericError`;
            });
    }

    return <div />;
};

export default FlowError;
