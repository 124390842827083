import { SupportedLanguages } from "@/types";
import { useState } from "react"

export function usePreferredLanguage() {

    const l = localStorage.getItem("locale")

    // TODO: we can use navigator.languages, similar to how it's done in assessment-form/src/App.tsx
    const [current, setCurrent] = useState<SupportedLanguages>("en-US")
    const [loading, setLoading] = useState(false)
    if (l && l !== current) {
        setCurrent(l as SupportedLanguages)
    }

    return {
        current,
        loading,
        set: async (lang: SupportedLanguages): Promise<boolean> => {
            setLoading(true)
            localStorage.setItem("locale", lang)
            const res = await fetch(import.meta.env.VITE_AUTHSIDECAR_PUBLIC_URL + "/api/v1/update-locale", {
                method: "POST",
                credentials: "include",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({ locale: lang })
            })
            setLoading(false)
            if (res.status !== 200) {
                console.error(res)
                return false
            }
            setCurrent(lang)
            return true
        }
    }
}
