import { LoadingButton } from "@mui/lab";
import { Alert, Box, Card, CircularProgress, Stack, TextField, Typography } from "@mui/material";
import * as Sentry from "@sentry/react";
import React from "react";
import { useIntl } from "react-intl";

import EmailVerificationModal from "@/components/EmailVerificationModal";
import { useRecoveryFlow } from "@/hooks/useRecoveryFlow";

import { useSession } from "@/hooks/useSession";
import { getCurrentDomain } from "@/utils/getCurrentHostname";
import { useSearchParams } from "react-router-dom";
import messages from "./messages";

const LinkRequiredPage: React.FC = () => {
    const { formatMessage } = useIntl();
    const [displayEmail, setDisplayEmail] = React.useState("");
    const recovery = useRecoveryFlow();
    const [searchParams] = useSearchParams();
    const initialEmail = searchParams.get("email");
    const [email, setEmail] = React.useState(initialEmail ?? "");

    const [sentAt, setSentAt] = React.useState(0);
    const [error, setError] = React.useState("");

    function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();

        const timeSinceLastSent = Date.now() - sentAt;
        if (timeSinceLastSent < 1000 * 60 * 1) {
            setError(formatMessage(messages.waitOneMinuteError));
            return;
        }
        const formData = new FormData(e.currentTarget);
        const email = formData.get("email") as string;
        setDisplayEmail(email);
        localStorage.setItem("sso-link-init", "1");
        if (!email) {
            return;
        }
        recovery
            .send(email)
            .then(() => setSentAt(Date.now()))
            .catch((e) => {
                Sentry.captureException(`failed to send recovery email: ${JSON.stringify(e)}`);
            });
    }

    const sess = useSession();
    if (sess.data) {
        window.location.href = getCurrentDomain() === "ignite" ? import.meta.env.VITE_IGNITE_APP_URL : import.meta.env.VITE_IGNITE_PROCUREMENT_APP_URL;
        return <CircularProgress />;
    }

    return (
        <Card sx={{ boxShadow: 4, px: 9, py: 6, pt: 8, pb: 16 }}>
            {sentAt > 0 && (
                <EmailVerificationModal
                    title={formatMessage(messages.mailTitle)}
                    description={formatMessage(messages.mailDescription, { email: displayEmail })}
                    closeButton
                />
            )}
            <Box
                minHeight={60} // enough height to not change layout if an Alert is present
                alignSelf="stretch"
            >
                {recovery.failed && <Alert severity="error">{formatMessage(messages.emailSentFailure)}</Alert>}
                {error && <Alert severity="error">{error}</Alert>}
            </Box>
            <Stack maxWidth="sm" gap={2} pb={8} alignItems="center">
                <Typography align="center" variant="h4">
                    {formatMessage(messages.linkTitle1)}
                </Typography>
                <Typography variant="body1">{formatMessage(messages.details)}</Typography>
            </Stack>
            <Stack component="form" onSubmit={handleSubmit} alignItems="center">
                <Stack gap={2}>
                    <TextField
                        variant="outlined"
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                        sx={{ minWidth: "350px" }}
                        autoComplete="username"
                        name="email"
                        label="your email address"
                    />
                    <LoadingButton loading={recovery.loading} type="submit" variant="contained" color="primary">
                        <span>{formatMessage(!sentAt ? messages.button : messages.resend)}</span>
                    </LoadingButton>
                </Stack>
            </Stack>
        </Card>
    );
};

export default LinkRequiredPage;
