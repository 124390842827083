import * as Sentry from "@sentry/react";
import React from "react";
import { oryClient } from "@/hooks/useSession";

async function initFlow(returnTo?: string): Promise<{ csrfToken: string; flow: string }> {
    let csrfToken = "",
        flow = "";
    const { data } = await oryClient.createBrowserRecoveryFlow({ returnTo });
    const csrfNode = data.ui.nodes.find((e) => e.group === "default");
    if (!csrfNode) {
        Sentry.captureMessage("failed to initialize recovery flow - no csrfNode found");
        return Promise.reject();
    }
    const attributes = csrfNode.attributes as unknown as { value: string };
    csrfToken = attributes.value;
    flow = data.id;
    return { csrfToken, flow };
}

export function useRecoveryFlow(returnTo?: string) {
    const [loading, setLoading] = React.useState(false);
    const [failed, setFailed] = React.useState(false);

    function send(email: string) {
        setLoading(true);
        return initFlow(returnTo)
            .then(({ csrfToken, flow }) => {
                return oryClient.updateRecoveryFlow({
                    flow,
                    updateRecoveryFlowBody: {
                        csrf_token: csrfToken,
                        email,
                        method: "link",
                    },
                });
            })
            .catch((e) => {
                setFailed(true);
                return Promise.reject(e);
            })
            .finally(() => setLoading(false));
    }

    return { send, failed, loading };
}
