import { LoadingButton } from "@mui/lab";
import * as Sentry from "@sentry/react";
import {
    Alert,
    CircularProgress,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { useSnackMessageContext } from "@/contexts/SnackMessageContext";

import { sessionExpired, updateName } from "@/hooks/settings";
import messages from "../messages";
import { SupportedLanguages } from "@/types";
import { usePreferredLanguage } from "./language";
import { track } from "@ignite-analytics/track";
import { useSession } from "@/hooks/useSession";

const ProfilePage: React.FC = () => {
    const sess = useSession();
    const preferredLanguage = usePreferredLanguage();
    const { formatMessage } = useIntl();
    const [alertMsg, alert] = useState("");
    const { onMessage } = useSnackMessageContext();
    const [loading, setLoading] = useState(false);
    // if you have a mid in metadataPublic, you're scimmed
    const isScimmed = !!sess?.data?.session?.identity?.metadata_public?.microsoftId;

    const onSubmit = useCallback(async (first: string, last: string) => {
        setLoading(true);
        const { error } = await updateName(first, last);
        if (error) {
            if (error.code == "session_refresh_required") {
                sessionStorage.setItem("updateNameKey", JSON.stringify({ first, last }));
                sessionExpired(window.location.pathname, { email: sess?.data?.traits?.email });
                return;
            }
            track("update profile failed: name", { code: error.code });
            Sentry.captureMessage("update profile failed: name", {
                tags: { code: error.code, details: error.details },
            });
            onMessage(formatMessage(messages.genericError));
            setLoading(false);
            return;
        }
        track("update profile: name", { first, last });
        onMessage(formatMessage(messages.profileUpdated));
        setLoading(false);
    }, [formatMessage, onMessage, sess]);

    useEffect(() => {
        const key = sessionStorage.getItem("updateNameKey");
        if (key) {
            sessionStorage.removeItem("updateNameKey");
            const { first, last } = JSON.parse(key);
            onSubmit(first, last);
        }
    }, [onSubmit]);

    async function onChangeLanguage(newLang: SupportedLanguages) {
        const ok = await preferredLanguage.set(newLang);
        if (ok) {
            track("update profile: language", { language: newLang });
            window.location.reload();
        } else {
            track("update profile: language failed", { language: newLang });
            Sentry.captureMessage("failed to update language preference");
            alert(formatMessage(messages.languageUpdateFailed));
        }
    }

    return (
        <Stack gap={4}>
            {alertMsg != "" && (
                <Alert severity="error" variant="outlined">
                    {alertMsg}
                </Alert>
            )}
            {isScimmed && (
                <Alert severity="info" variant="outlined">
                    {formatMessage(messages.scimmed)}
                </Alert>
            )}
            <Stack
                component="form"
                onSubmit={(e) => {
                    e.preventDefault();
                    const fd = new FormData(e.currentTarget);
                    const first = fd.get("firstName") as string;
                    const last = fd.get("lastName") as string;
                    onSubmit(first, last);
                }}
                gap={2}
            >
                <Typography pb={4} variant="h4">{formatMessage(messages.profileHeader)}</Typography>
                {sess.data !== null && (
                    <Stack spacing={3}>
                        <TextField
                            defaultValue={sess.data.traits.name.first}
                            size="small"
                            name="firstName"
                            label={formatMessage(messages.labelFirstname)}
                            disabled={isScimmed}
                        />
                        <TextField
                            defaultValue={sess.data.traits.name.last}
                            size="small"
                            name="lastName"
                            label={formatMessage(messages.labelLastname)}
                            disabled={isScimmed}
                        />
                        <TextField
                            disabled
                            value={sess.data.traits.email}
                            size="small"
                            label={formatMessage(messages.email)}
                            variant="outlined"
                        />
                        <Stack direction="row" justifyContent="flex-start">
                            <LoadingButton
                                variant="contained"
                                loading={loading}
                                type="submit"
                                disabled={isScimmed}
                            >
                                {formatMessage(messages.save)}
                            </LoadingButton>
                        </Stack>
                    </Stack>
                )}
            </Stack>
            <Stack gap={4} justifyItems="center">
                {!preferredLanguage.loading && (
                    <FormControl variant="outlined">
                        <InputLabel>{formatMessage(messages.labelLanguage)}</InputLabel>
                        <Select
                            label={formatMessage(messages.labelLanguage)}
                            name="language"
                            value={preferredLanguage.current}
                            onChange={(e) => onChangeLanguage(e.target.value as string as SupportedLanguages)}
                        >
                            <MenuItem value="en-US">English</MenuItem>
                            <MenuItem value="de-DE">Deutsch</MenuItem>
                            <MenuItem value="nb-NO">Norsk</MenuItem>
                        </Select>
                    </FormControl>
                )}
                {preferredLanguage.loading && <CircularProgress />}
            </Stack>
        </Stack>
    );
};
export default ProfilePage;
