import { defineMessages } from "react-intl";

const messages = defineMessages({
    emailDescription: {
        defaultMessage:
            "Please check your inbox and follow the instructions provided in your email to recover your password.",
        id: "emailDescription",
    },
    emailTitle: {
        defaultMessage: "A recovery-email has been sent to {email}",
        id: "emailTitle",
    },
    submit: {
        defaultMessage: "submit",
        id: "submit",
    },
    emailLabel: {
        defaultMessage: "Email",
        id: "emailLabel",
    },
    body: {
        defaultMessage: "Enter your email address to recover your account.",
        id: "recovery.body",
    },
    title: {
        defaultMessage: "Recover your account",
        id: "title",
    },
});

export default messages;
