import { identify, track } from "@ignite-analytics/track";
import { LoadingButton } from "@mui/lab";
import { Alert, Card, CircularProgress, Divider, Link, Stack, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Navigate, useSearchParams } from "react-router-dom";

import MicrosoftLogo from "../../assets/ms-logo.svg";

import { useSession } from "@/hooks/useSession";
import { getCurrentDomain } from "@/utils/getCurrentHostname";
import { VerifyEmailDialog } from "./VerifyEmailDialog";
import useRegistration from "./hooks";
import messages from "./messages";
import { useNewIgnite } from "@/utils/useNewIgnite";

const TERMS_LINK = "https://www.igniteprocurement.com/security-and-legal/terms-and-conditions";

function RegisterPage() {
    const { formatMessage } = useIntl();

    const [query] = useSearchParams();
    const [disabledPasswordRegistration, setDisabledPasswordRegistration] = useState(false);
    const { register, error: registrationError, loading } = useRegistration();
    const [verificationEmail, setVerificationEmail] = useState<string>();
    const [searchParams] = useSearchParams();

    const loginHint = searchParams.get("email");

    useEffect(() => {
        const v = query.get("allowPasswordRegistration");
        if (v === "false" || v == "0") {
            setDisabledPasswordRegistration(true);
        }
    }, [query]);

    function handleMicrosoftSocialSigninSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        return register.socialSignIn.microsoft(loginHint).then(() => {
            track("New user registered using Microsoft login");
        });
    }
    const newDesign = useNewIgnite();

    function handlePasswordSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault();
        const form = new FormData(e.currentTarget);
        const data = {
            name: { first: form.get("firstName") as string, last: form.get("lastName") as string },
            email: (form.get("email") as string) ?? loginHint,
            password: form.get("password") as string,
        };
        return register
            .password(data.password, data.email, { first: data.name.first, last: data.name.last })
            .then(() => {
                sessionStorage.setItem("email", data.email);
                if (newDesign) {
                    return <Navigate to="/register-complete" />;
                }
                setVerificationEmail(data.email);
                identify({ email: data.email, type: "anonymous" });
                track("New user registered");
            })
            .catch(() => {
                // do nothing - error is handled by the hook
            });
    }

    const sess = useSession();
    if (sess.data) {
        window.location.href =
            getCurrentDomain() === "ignite"
                ? import.meta.env.VITE_IGNITE_APP_URL
                : import.meta.env.VITE_IGNITE_PROCUREMENT_APP_URL;
        return <CircularProgress />;
    }
    if (newDesign) {
        // design spec: https://www.figma.com/design/aFamF21J5oQRAGmOF83WMr/Log-in-%2F-Account-creation?node-id=7-5983&node-type=text&m=dev
        return (
            <Card sx={{ py: 4, px: 3, width: "444px" }}>
                {/* content */}
                <Stack gap={4.5} alignItems="center">
                    {/* header */}
                    <Stack gap={1} alignItems="center">
                        <Typography justifySelf="center" variant="textXl" fontWeight="500">
                            <FormattedMessage defaultMessage="Register new user" />
                        </Typography>
                        <Typography variant="textSm">
                            <FormattedMessage defaultMessage="Once registered you will be able to create/access your workspace." />
                        </Typography>
                    </Stack>
                    {/* frame 3 */}
                    <Stack gap={3} alignItems="center">
                        {/* Social Signup */}
                        <Stack
                            minWidth="100%"
                            onSubmit={handleMicrosoftSocialSigninSubmit}
                            justifyItems="stretch"
                            component="form"
                        >
                            <LoadingButton
                                fullWidth
                                type="submit"
                                startIcon={<img width={24} alt="microsoft logo" src={MicrosoftLogo} />}
                                variant="outlined"
                                color="secondary"
                                loading={loading.socialSignIn.microsoft}
                            >
                                <FormattedMessage defaultMessage="Continue with Microsoft" />
                            </LoadingButton>
                        </Stack>

                        {/* or */}
                        <Typography>
                            <FormattedMessage defaultMessage="Or" />
                        </Typography>

                        {/*forms */}
                        <Stack
                            gap={2}
                            alignItems="center"
                            component="form"
                            id="password-form"
                            onSubmit={handlePasswordSubmit}
                        >
                            {/*email */}
                            <TextField
                                required={true}
                                disabled={disabledPasswordRegistration || loginHint !== null}
                                variant="outlined"
                                fullWidth
                                name="email"
                                defaultValue={loginHint}
                                placeholder={formatMessage({ defaultMessage: "Email" })}
                                label={<FormattedMessage defaultMessage="Email" />}
                            />

                            <Stack direction="row" gap={2}>
                                <TextField
                                    disabled={disabledPasswordRegistration}
                                    required
                                    variant="outlined"
                                    placeholder={formatMessage({ defaultMessage: "First Name" })}
                                    name="firstName"
                                    label={<FormattedMessage defaultMessage="First Name" />}
                                />
                                <TextField
                                    disabled={disabledPasswordRegistration}
                                    placeholder={formatMessage({ defaultMessage: "Last Name" })}
                                    required
                                    variant="outlined"
                                    name="lastName"
                                    label={<FormattedMessage defaultMessage="Last Name" />}
                                />
                            </Stack>

                            <TextField
                                disabled={disabledPasswordRegistration}
                                placeholder={formatMessage({ defaultMessage: "Password" })}
                                required
                                type="password"
                                fullWidth
                                inputProps={{ minLength: 8 }}
                                variant="outlined"
                                name="password"
                                label={<FormattedMessage defaultMessage="Password" />}
                            />
                            {/* we'll add a hidden submit here to allow clicking enter */}
                            <input type="submit" style={{ display: "none" }} />
                        </Stack>
                        {/* actions */}
                        <Stack gap={3} alignItems="center" width="100%">
                            <LoadingButton
                                fullWidth
                                disabled={disabledPasswordRegistration}
                                type="submit"
                                form="password-form"
                                loading={loading.password}
                            >
                                <FormattedMessage defaultMessage="Register" />
                            </LoadingButton>
                            <Typography variant="textSm">
                                <FormattedMessage
                                    defaultMessage="By registering, you agree to our {termsLink}"
                                    values={{
                                        termsLink: (
                                            <Link href={TERMS_LINK} target="_blank">
                                                {formatMessage(messages.termsLink)}
                                            </Link>
                                        ),
                                    }}
                                />
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </Card>
        );
    }

    return (
        <Card sx={{ boxShadow: 4, px: 9, py: 6, pt: 8 }}>
            <Stack alignItems="center">
                <VerifyEmailDialog open={!!verificationEmail} email={verificationEmail} />
                {registrationError && (
                    <Alert sx={{ my: 2 }} severity="error">
                        {registrationError}
                    </Alert>
                )}
                <Stack alignItems="center" gap={2} maxWidth={400}>
                    <Typography justifySelf="center" variant="h4">
                        <FormattedMessage defaultMessage="Register new user" />
                    </Typography>
                    <Typography pb={8} variant="subtitle1">
                        {formatMessage(messages.registerBody)}
                    </Typography>

                    {/* Social Signup */}
                    <Stack
                        minWidth="100%"
                        onSubmit={handleMicrosoftSocialSigninSubmit}
                        justifyItems="stretch"
                        component="form"
                    >
                        <LoadingButton
                            fullWidth
                            type="submit"
                            startIcon={<img width={24} alt="microsoft logo" src={MicrosoftLogo} />}
                            variant="outlined"
                            color="secondary"
                            loading={loading.socialSignIn.microsoft}
                        >
                            <span>{formatMessage(messages.continueWithMicrosoft)}</span>
                        </LoadingButton>
                    </Stack>

                    <Stack alignSelf="stretch">
                        <Divider>
                            <Typography>{formatMessage(messages.or)}</Typography>
                        </Divider>
                    </Stack>

                    {disabledPasswordRegistration && (
                        <Alert severity="info">{formatMessage(messages.noPasswordSignupInfo)}</Alert>
                    )}

                    {/* email signup */}
                    <Stack
                        component="form"
                        onSubmit={handlePasswordSubmit}
                        style={disabledPasswordRegistration ? { filter: "blur(2px)" } : {}}
                        rowGap={3}
                    >
                        <TextField
                            required={true}
                            disabled={disabledPasswordRegistration || loginHint !== null}
                            variant="outlined"
                            name="email"
                            defaultValue={loginHint}
                            label={formatMessage(messages.email)}
                        />
                        <Stack direction="row" gap={1}>
                            <TextField
                                disabled={disabledPasswordRegistration}
                                required
                                variant="outlined"
                                name="firstName"
                                label={formatMessage(messages.firstName)}
                            />
                            <TextField
                                disabled={disabledPasswordRegistration}
                                required
                                variant="outlined"
                                name="lastName"
                                label={formatMessage(messages.lastName)}
                            />
                        </Stack>
                        <TextField
                            disabled={disabledPasswordRegistration}
                            required
                            type="password"
                            // min length 8
                            inputProps={{ minLength: 8 }}
                            variant="outlined"
                            name="password"
                            label={formatMessage(messages.password)}
                        />
                        <LoadingButton
                            sx={{ mt: 1 }}
                            disabled={disabledPasswordRegistration}
                            type="submit"
                            variant="outlined"
                            color="secondary"
                            loading={loading.password}
                        >
                            <span>{formatMessage(messages.submit)}</span>
                        </LoadingButton>
                    </Stack>
                    <Typography variant="caption" sx={{ pt: 1 }}>
                        {formatMessage(messages.termsAndConditions, {
                            termsLink: (
                                <Link href={TERMS_LINK} target="_blank">
                                    {formatMessage(messages.termsLink)}
                                </Link>
                            ),
                        })}
                    </Typography>
                </Stack>
            </Stack>
        </Card>
    );
}

export default RegisterPage;
